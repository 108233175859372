import { Route, Navigate, Routes } from "react-router-dom";
import NavBar from "./components/header/NavBar";
import MyOrders from "./components/my-orders/my-orders";
import NewOrder from "./components/new-order/NewOrder";
import SignIn from "./components/sign-in/sign-in";
import Profile from "./components/profile/profile";
import makeStyles from '@mui/styles/makeStyles';
import Footer from "./components/footer/Footer";
import Order from "./components/gallery/gallery";
import S3Uploader from "./components/download/S3Uploader";
import ViewDummy from "./components/gallery/ViewDummy";

// Google Analytics // doesn't work with v.4
// import ReactGA from "react-ga";
// const TRACKING_ID = "G-YE1QZ6Y622"; // My.zentuvo.com
// ReactGA.initialize(TRACKING_ID);

// Google Analytics 4
import ReactGA from "react-ga4";
//ReactGA.initialize("G-SGDEBZB36K"); // my.zentuvo.com
ReactGA.initialize("G-HS90H4TPV5"); // etuovi.zentuvo.com
ReactGA.send("pageview");


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div id="NavBar">
        <NavBar />
      </div>

      <main className={classes.main}>
        <Routes>
          {/* <Route path="/profile" element={(props) => <Profile {...props} />} /> */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/new-order" element={<NewOrder />} />
          <Route path="/uploader" element={<S3Uploader />} />
          <Route path="/view" element={<ViewDummy />} />
          <Route path="/order" element={<Order />} />
          {/* <Route
            path="/privacy-policy"
            render={(props) => <PrivacyPolicy {...props} />}
          /> */}
          <Route
            path="/contact-us"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/contact/";
              return null;
            }}
          />
          <Route
            path="/ota-meihin-yhteyttä"
            component={() => {
              window.location.href = "https://zentuvo.fi/yhteys/";
              return null;
            }}
          />

          <Route
            path="/team"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/about-us/#staff";
              return null;
            }}
          />
          <Route
            path="/tiimi"
            component={() => {
              window.location.href = "https://zentuvo.fi/meista/#staff";
              return null;
            }}
          />

          <Route
            path="/join-the-team"
            component={() => {
              window.location.href = "https://zentuvo.recruitee.com/#/";
              return null;
            }}
          />
          <Route
            path="/liity-tiimiin"
            component={() => {
              window.location.href = "https://zentuvo.recruitee.com/#/";
              return null;
            }}
          />

          <Route
            path="/photo"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/photo/";
              return null;
            }}
          />
          <Route
            path="/kuvat"
            component={() => {
              window.location.href = "https://zentuvo.fi/kuvat/";
              return null;
            }}
          />

          <Route
            path="/video"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/video/";
              return null;
            }}
          />
          <Route
            path="/digital"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/digital/";
              return null;
            }}
          />
          <Route
            path="/visualisation"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/visualisation/";
              return null;
            }}
          />
          <Route
            path="/preparations"
            component={() => {
              window.location.href = "https://zentuvo.fi/en/preparations/";
              return null;
            }}
          />
          <Route
            path="/valmistelut"
            component={() => {
              window.location.href = "https://zentuvo.fi/valmistelut/";
              return null;
            }}
          />
          <Route
            path="/terms-of-use"
            component={() => {
              window.location.href =
                "https://zentuvo.fi/en/terms-and-conditions/";
              return null;
            }}
          />
          <Route path="/" element={<Navigate replace to="/new-order" />} />
          {/* <Navigate from="/" exact to="/new-order" /> */}
          {/* <Navigate to="/not-found" /> */}
        </Routes>
      </main>

      <footer className={classes.footer}>
        <Footer style={{ width: "100%" }} />
      </footer>
    </div>
    // </ThemeProvider>
  );
}

export default App;
