import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

import Popover from "@mui/material/Popover";

import { useState, useEffect } from "react";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";

import MuiPhoneNumber from "material-ui-phone-number";

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import Loading from "../misc/Loading";
import Error from "../misc/Error";

import MUIDataTable from "mui-datatables";
import { TableFooter, TableCell, TableRow } from "@mui/material";

import { useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useStickyState } from "../../utils/useStickyState";
import S3UploadBeforeOrder from "../download/S3UploadBeforeOrder";

//ReactGA.initialize("G-SGDEBZB36K"); // my.zentuvo.com
ReactGA.initialize("G-HS90H4TPV5"); // etuovi.zentuvo.com
ReactGA.send("pageview");

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  gridItemCard: {
    "& .MuiGrid-item": {
      padding: 0,
    },

    margin: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardHeader: {
    justifyContent: "flex-start",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  card6Root: {
    minWidth: 275,
    marginBottom: theme.spacing(1),
  },
  root1: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonAsLink: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  selectPreferredTime: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  popoverTypography: {
    padding: theme.spacing(2),
  },
}));


function extractFromAdress(components, type) {
  for (var i = 0; i < components.length; i++)
    for (var j = 0; j < components[i].types.length; j++)
      if (components[i].types[j] === type) {
          return components[i].long_name;
        }
  return "";
}

const searchOptions = {
  componentRestrictions: { country: ["fi"] },
};

// TODO: move it to utils.js
// const reEmail =     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const reEmailUnicode =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
const VAT = 0.24;
const uploadFloorplanFolderPrefix = "Webshop_Floorplans_";
export default function NewOrder(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [queryParams, setQueryParams] = useSearchParams();
  const [showUploader, setShowUploader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(new Set());
  const handleUploadComplete = (files) => {
    const combinedFiles = new Set([...uploadedFiles, ...files]);
    setUploadedFiles(combinedFiles);
  };
  const [extOrderId, setExtOrderId] = useState(new Date().toISOString().replaceAll(":","").replaceAll(".",""));
  
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [isTermsAndConditionAccepted, setTermsAndConditionAccepted] =
    useStickyState(false, "isTermsAndConditionAccepted");

  const handleChangeAcceptTermsAndConditionCheckbox = () => {
    setTermsAndConditionAccepted(!isTermsAndConditionAccepted);
  };

  const step0Ref = useRef(); //< HTMLInputElement > null;
  const step1Ref = useRef(); // < HTMLInputElement > null;
  const step2Ref = useRef(); // < HTMLInputElement > null;
  const step3Ref = useRef(); // < HTMLInputElement > null;
  const step4Ref = useRef(); // < HTMLInputElement > null;
  const step5Ref = useRef(); // < HTMLInputElement > null;
  // const step6Ref = useRef(); // < HTMLInputElement > null;
  const step7Ref = useRef(); // < HTMLInputElement > null;

  const [typeOfPropertySelected, setTypeOfPropertySelected] = useStickyState(
    null,
    "SelectedTypeOfProperty"
  );

  const [typesOfProperty, setTypesOfProperty] = useState(
    [] // typesOfPropertyInitial
  );

  const [sizeOfPropertySelected, setSizeOfPropertySelected] = useStickyState(
    null,
    "SelectedSizeOfProperty"
  );

  const [sizesOfProperty, setSizesOfProperty] = useState(
    [] // sizesOfPropertyInitial
  );

  const [skus4category, setSKUs4category] = useStickyState([], "skus4category");
  const [productsCategories, setProductsCategories] = useState(
    [] // sizesOfPropertyInitial
  );

  const [packageSelected, setPackageSelected] = useStickyState(
    null,
    "SelectedPackage"
  );
  const [products, setProducts] = useState([]);

  const [googleAddress, setGoogleAddress] = useState("");
  const handleGoogleAddressSelect = (address) => {
    setGoogleAddress(address);
    geocodeByAddress(address)
      .then((results) => {

        const fullAddress = results[0].address_components;
        setAddress1Field(
          extractFromAdress(fullAddress, "route") +
            " " +
            extractFromAdress(fullAddress, "street_number")
        );
        setZipField(extractFromAdress(fullAddress, "postal_code"));

        /* extract City */
        let city =  extractFromAdress(fullAddress, "locality");
        if (!city) {
          city =  extractFromAdress(fullAddress, "administrative_area_level_3");
        }
        if (!city) {
          city =  extractFromAdress(fullAddress, "administrative_area_level_1");
        }
        if (!city) {
          city =  extractFromAdress(fullAddress, "sublocality");
        }

        setCityField(city);

        return getLatLng(results[0]);
      })
      .then(async (latLng) => {
        let phCoords = await fetch(
          `${process.env.REACT_APP_API_URL}getPhotographersCoordinates/${latLng.lat}/${latLng.lng}`
        ).catch(handleAPIError);
        // console.log(phCoords);
        if (phCoords.ok && phCoords.status === 202) {
          ReactGA.event({
            category: "New Order",
            action: "Address",
            label: "Address Selected Successfully", // optional
            value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        } else {
          ReactGA.event({
            category: "New Order",
            action: "Address",
            label: "Address is not under service", // optional
            value: 98, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          // alert(t("zipIsNOTUnderService"));
          // try {
          //   step4Ref.current.scrollIntoView();
          // } catch {
          //   console.error("can't scroll at line #282");
          // }
          //setActiveStep(4); we allow user to proceed in case address not under service.
          return Promise.reject(phCoords);
        }
      })
      .catch((error) => {
        alert(t("zipIsNOTUnderService"));
        try {
          step4Ref.current.scrollIntoView();
        } catch {
          console.error("can't scroll at line #282");
        }
        //setActiveStep(4); we allow user to proceed in case address not under service.

        if (error.status === 204) {

          ReactGA.event({
            category: "New Order",
            action: "Address",
            label: "ZipIsNotUnderService", // optional
            value: 97, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        } else {
          ReactGA.event({
            category: "New Order",
            action: "Address",
            label: "Error while selecting Address " + error.status, // optional
            value: 96, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        }

      });
  };
  const [address1Field, setAddress1Field] = useStickyState("", "address1");
  const [cityField, setCityField] = useStickyState("", "city");
  const [nameondoorbellField, setNameondoorbellField] = useStickyState(
    "",
    "nameondoorbell"
  );
  const [apartmentField, setApartmentField] = useStickyState("", "apartment");
  const [zipField, setZipField] = useStickyState("", "zip");

  const [emailField, setEmailField] = useStickyState("", "email");

  const [isEmailWrong, setEmailWrong] = useState(false);
  const [verificationCodeField, setVerificationCodeField] = useStickyState(
    "",
    "verification_code"
  );

  const [firstName, setFirstName] = useStickyState("", "first_name");
  const [lastName, setLastName] = useStickyState("", "last_name");
  const [phone1Field, setPhone1Field] = useStickyState("", "phone1");
  const [phone2Field, setPhone2Field] = useStickyState("", "phone2");

  const [preferredDateField, setPreferredDateField] = useStickyState(
    "",
    "preferredDate"
  );
  const handleChangePreferredDateField = (newDate) => {
    //console.log("handleChangePreferredDateField");
    var date = new Date(newDate);
    var day = date.getDay();
    if (day === 0 || day === 6) {
      //0 for Sunday, 6 for Saturday
      alert(t("preferredDateNoWeekend"));

      ReactGA.event({
        category: "New Order",
        action: "PreferredDate",
        label: "Preferred Date NoWeekend", // optional
        value: 95, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    } else {
      setPreferredDateField(newDate);

      ReactGA.event({
        category: "New Order",
        action: "PreferredDate",
        label: "Preferred Date OK", // optional
        value: 94, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }
  };
  const [preferredTimeField, setPreferredTimeField] = useStickyState(
    "ANY_TIME",
    "preferredTime"
  );

  const [prevStep, setPrevStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  var handleAPIError = function (err) {
    console.error("handleAPIError", err);
    setError({
      message:
        "Sorry. Failed to fetch info from Server. Check you internet connection. If it is OK, then just wait a little bit probably there is proplem with interet connection on server. Original Error: " +
        err.message,
    });
    return new Response(
      JSON.stringify({
        code: 400,
        message: "Some network Error: " + err.message,
      })
    );
  };

  useEffect(() => {
    //console.log("useEffect []");
    setError(null);
    setLoaded(false);
    async function fetchMyAPI() {

      let types = await fetch(
        process.env.REACT_APP_API_URL + "getProductsSettings/types"
      ).catch(handleAPIError);
      let sizes = await fetch(
        process.env.REACT_APP_API_URL + "getProductsSettings/sizes"
      ).catch(handleAPIError);
      let categories = await fetch(
        process.env.REACT_APP_API_URL + "getProductsSettings/categories"
      ).catch(handleAPIError);
      let products1 = await fetch(
        process.env.REACT_APP_API_URL + "products"
      ).catch(handleAPIError);

      if (types.ok) {
        // console.log("types.headers.get(content-type)=", types.headers.get("content-type"));

        let typesOfPropertyJson = await types.json();
        /* this fix is for SE360 since it retrun string not JSON Object */
        if (typeof typesOfPropertyJson === 'string') {
          typesOfPropertyJson = JSON.parse(typesOfPropertyJson);
        }

        //const typesOfPropertyJson = await types.json();
        // console.log("typesOfPropertyJson=", typesOfPropertyJson);
        setTypesOfProperty(typesOfPropertyJson);
        
        if (queryParams.has("type")) {
          /* URL contains query param "type" which means typeOfProperty is pre-selected */
          const preSelectionId = queryParams.get("type").toLowerCase();

          const preSelection = typesOfPropertyJson.find(obj => obj.id === preSelectionId);

          setTypeOfPropertySelected(preSelection);
          //console.log("setActiveStep(1) curr activeStep=" + activeStep);
          setPrevStep(activeStep);
          setActiveStep(1);
        }
      } else {
        return Promise.reject(types);
      }

      if (sizes.ok) {
        // console.log("sizes.resp=", sizes);
        let sizesOfPropertyJson = await sizes.json();
        /* this fix is for SE360 since it retrun string not JSON Object */
        if (typeof sizesOfPropertyJson === 'string') {
          sizesOfPropertyJson = JSON.parse(sizesOfPropertyJson);
        }
        
        // console.log("sizesOfPropertyJson=", sizesOfPropertyJson);
        setSizesOfProperty(sizesOfPropertyJson);

        if (queryParams.has("size") && typeOfPropertySelected && typeOfPropertySelected.id) {

          /* URL contains query param "size" which means sizesOfProperty is pre-selected */
          const preSelectionId = `FI-${typeOfPropertySelected.id}-${queryParams.get("size").toLowerCase()}`; // usually size is number with minus like: "51-100", so, can be removed

          const preSelection = sizesOfPropertyJson.find(obj => obj.id === preSelectionId);

          setSizeOfPropertySelected(preSelection);
          //console.log("setActiveStep(2) curr activeStep=" + activeStep);
          setPrevStep(activeStep);
          setActiveStep(2);
        }
      } else {
        return Promise.reject(sizes);
      }

      if (categories.ok) {
        let cat = await categories.json();
        /* this fix is for SE360 since it retrun string not JSON Object */
        if (typeof cat === 'string') {
          cat = JSON.parse(cat);
        }
        // console.log("categories=", cat, typeof cat);
        
        if (cat.code === 400) {
          setError(cat.message);
          return;
        }
        setProductsCategories(cat);
      } else {
        return Promise.reject(categories);
      }

      if (products1.ok) {
        let products2 = await products1.json();

        let iGo2Step = 2;
        let products2select = [];
        if (queryParams.has("package") ) {
          products2select.push(queryParams.get("package"));
          iGo2Step = 3;
        }
        if (queryParams.has("product") ) {
          products2select = products2select.concat(queryParams.getAll("product"));
          iGo2Step = 4;
        }

        if (products2select.length > 0) {
          products2.forEach((p) => {
            if (products2select.includes(p.sku)) {
              p.selected = true;
            }
          });
          setPackageSelected(products2.find(p => p.selected && p.type === "package"));
        } else if (packageSelected) { /* select product from packageSelected if it saved */
          products2.forEach((p) => {
            if (p.sku === packageSelected.sku) {
              p.selected = packageSelected.selected;
              iGo2Step = 3;
            }
          });
        }

        setProducts(products2);

        setPrevStep(activeStep);
        setActiveStep(iGo2Step);
      } else {
        return Promise.reject(products1);
      }

      setLoaded(true);
    }

    fetchMyAPI();
  }, []);
  
  useEffect(() => {
    //console.log("useEffect [activeStep] " + activeStep + " prevStep=" + prevStep);

    /* validation on NEXT */
    if (activeStep > 0) {
      if (!typeOfPropertySelected) {
        alert(
          t("Please select type of property")
        );
        //console.log("setActiveStep(0) curr activeStep=" + activeStep);
        setPrevStep(activeStep);
        setActiveStep(0);
        return;
      }
    }

    if (activeStep === 1 && typeOfPropertySelected) {
      /* count number of sizesOfProperty for typeOfPropertySelected and 
      * automatically select SizeOfProperty if there is only one for typeOfPropertySelected and
      * go to next Step
      */
      const arSizesOfSelectedType = sizesOfProperty.filter(
        (sizeOfProperty) => sizeOfProperty.type === typeOfPropertySelected.id
      );

      // automatically select SizeOfProperty if there is only one for typeOfPropertySelected
      if (arSizesOfSelectedType.length === 1) {
        if (prevStep > activeStep) {
          setPrevStep(activeStep);
          //console.log("useEffect [activeStep]1 go back. setActiveStep=" + (activeStep-1));
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
          setSizeOfPropertySelected(arSizesOfSelectedType[0]);

          setPrevStep(activeStep);
          //console.log("useEffect [activeStep]2 go next. setActiveStep=" + (activeStep+1));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }

    if (activeStep > 1) {
      if (sizeOfPropertySelected === null) {
        alert(t("Please select size of property"));
        //console.log("setActiveStep(1) curr activeStep=" + activeStep);
        setPrevStep(activeStep);
        setActiveStep(1);
        return;
      }
    }
    if (activeStep === 2) {
      const suitablePackages = products.filter(
        (product) =>
          skus4category.includes(product.sku) &&
          product.type.toUpperCase() === "PACKAGE"
      )
      
      if (suitablePackages.length === 0) {
        if (prevStep > activeStep) {
          setPrevStep(activeStep);
          //console.log("useEffect [activeStep]1 go back. setActiveStep=" + (activeStep-1));
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
          setPrevStep(activeStep);
          //console.log("useEffect [activeStep]2 go next. setActiveStep=" + (activeStep+1));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
    if (activeStep === 3) {
      const suitablePackages = products.filter(
        (product) =>
          skus4category.includes(product.sku) &&
          product.type.toUpperCase() === "STANDALONE"
      )
      
      if (suitablePackages.length === 0) {
        if (prevStep > activeStep) {
          setPrevStep(activeStep);
          //console.log("useEffect [activeStep]3 go back. setActiveStep=" + (activeStep-1));
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
          setPrevStep(activeStep);
          //console.log("useEffect [activeStep]4 go next. setActiveStep=" + (activeStep+1));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
    if (activeStep > 3) {
      if (!products.some((product) => product.selected)) {
        alert(t("Please select at least one product"));
        //console.log("setActiveStep(2) curr activeStep=" + activeStep);
        setPrevStep(activeStep);
        setActiveStep(2);
        return;
      }
    }
    if (activeStep > 4) {
      if (!address1Field) {
        alert(t("MandatoryAddress"));
        setPrevStep(activeStep);
        setActiveStep(4);
        return;
      }
      if (!cityField) {
        alert(t("MandatoryCity"));
        setPrevStep(activeStep);
        setActiveStep(4);
        return;
      }
      if (!zipField) {
        alert(t("MandatoryZip"));
        setPrevStep(activeStep);
        setActiveStep(4);
        return;
      }
      if (zipField.length < 5) {
        alert(t("InvalidZip"));
        setPrevStep(activeStep);
        setActiveStep(4);
        return;
      }
      let addr = [];
      addr.push(address1Field);
      addr.push(cityField);
      addr.push(zipField);
      addr.push("Finland");

      handleGoogleAddressSelect(addr.join(", "));

      /* check if we already have email and pwd in LocalStorage, maybe user has signed in after page is loaded */
      if (!emailField) {
        const email = JSON.parse(window.localStorage.getItem("email"));
        if (email) setEmailField(email);
      }
      if (!verificationCodeField) {
        const verificationCode = JSON.parse(
          window.localStorage.getItem("verification_code")
        );
        if (verificationCode) setVerificationCodeField(verificationCode);
      }
    }
    if (activeStep > 5) {
      if (!emailField) {
        alert(t("Please enter your E-mail address to receive password."));
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      } else if (isEmailWrong) {
        alert(t("EmailIsWrong"));
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      }
      if (!verificationCodeField) {
        alert(t("Fill password sent to your E-mail to proceed"));
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      }
      fetch(
        process.env.REACT_APP_API_URL + "verifyEmailAndCode",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: emailField,
            code: verificationCodeField,
          }),
        })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          if (res.status === 401) {
            alert(t("PasswordIsDifferent"));
          } else {
            setError({message: res.statusText});
          }
          // console.log(res.status, res.statusText, res);
          setPrevStep(activeStep);
          setActiveStep(5);
          //throw res.statusText;
          return Promise.reject(res.statusText);
        }
      })
      .then(res => res.json())
      .then(data => {
        if (!firstName) setFirstName(data.first_name);
        if (!lastName) setLastName(data.last_name);
        if (!phone1Field) setPhone1Field(data.phone1);
        if (!phone2Field) setPhone2Field(data.phone2);
      })
      //.catch(handleAPIError);
      .catch((error) => {
        console.error(error);
      });
    }

    if (activeStep > 6) {
      if (!firstName /*&& !data.first_name*/) {
        alert(t("MandatoryFirstName"));
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      } else if (!lastName /* && !data.last_name*/) {
        alert(t("MandatoryLastName"));
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      } else if (!phone1Field /* && !data.phone1*/) {
        alert(t("MandatoryPhoneMain"));
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      }

      updateContacts();
    }

    if (activeStep > 7) {
      if (!products.some((product) => product.selected)) {
        alert(t("Please select at least one product"));
        //console.log("setActiveStep(2) curr activeStep=" + activeStep);
        setPrevStep(activeStep);
        setActiveStep(2);
        return;
      }
    }
    if (activeStep >= 8) {
      if (isTermsAndConditionAccepted) {
        handleSubmitOrder();
      } else {
        //console.log("setActiveStep(" + (activeStep - 1) + ") curr activeStep=" + activeStep);
        setPrevStep(activeStep);
        setActiveStep(activeStep - 1);
      }
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      typesOfProperty.length > 0 &&
      typeOfPropertySelected != null &&
      typeOfPropertySelected.selected
    ) {
      //console.log("useEffect [typeOfPropertySelected, typesOfProperty.length], setActiveStep(1) curr activeStep=" + activeStep + " prevStep=" + prevStep);
      setPrevStep(activeStep);
      setActiveStep(1);

      ReactGA.event({
        category: "New Order",
        action: "typeOfPropertySelected",
        label: "lblTypeOfPropertySelected", // optional
        value: 93, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }


    if (typeOfPropertySelected) {
      /* unselect sizeOfProperty if sizeOfPropertySelected.type is different than new typeOfPropertySelected */
      if (sizeOfPropertySelected && typeOfPropertySelected.id !== sizeOfPropertySelected.type) {
        setSizeOfPropertySelected(null);
      }

      /* count number of sizesOfProperty for typeOfPropertySelected and 
      * automatically select SizeOfProperty if there is only one for typeOfPropertySelected and
      * go to next Step
      */
      const arSizesOfSelectedType = sizesOfProperty.filter(
        (obj) => obj.type === typeOfPropertySelected.id
      );

      // automatically select SizeOfProperty if there is only one for typeOfPropertySelected
      if (arSizesOfSelectedType.length === 1) {
        setSizeOfPropertySelected(arSizesOfSelectedType[0]);

        const skus4categoryTmp = resetSKUs4Category();

        const suitablePackages = products.filter(
          (product) =>
            skus4categoryTmp.includes(product.sku) &&
            product.type.toUpperCase() === "PACKAGE"
        )
        if (suitablePackages.length > 0) {
          //console.log("useEffect [typeOfPropertySelected, typesOfProperty.length], setActiveStep(2) curr activeStep=" + activeStep + " prevStep=" + prevStep);
          setPrevStep(activeStep);
          setActiveStep(2);
        } else {
          //console.log("useEffect [typeOfPropertySelected, typesOfProperty.length], setActiveStep(3) curr activeStep=" + activeStep + " prevStep=" + prevStep);
          setPrevStep(activeStep);
          setActiveStep(3); // skip Package Selection
        }
      }
    }
  }, [typeOfPropertySelected, typesOfProperty.length]);

  useEffect(() => {
    if (
      sizesOfProperty.length > 0 &&
      sizeOfPropertySelected != null &&
      sizeOfPropertySelected.selected
    ) {
      //console.log("setActiveStep(2) curr activeStep=" + activeStep);
      setPrevStep(activeStep);
      setActiveStep(2);

      ReactGA.event({
        category: "New Order",
        action: "sizeOfPropertySelected",
        label: "lblSizeOfPropertySelected", // optional
        value: 92, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }
  }, [sizeOfPropertySelected, sizesOfProperty.length]);

  useEffect(() => {
    if (
      products.length > 0 &&
      packageSelected != null &&
      packageSelected.selected
    ) {
      //console.log("setActiveStep(3) curr activeStep=" + activeStep);
      setPrevStep(activeStep);
      setActiveStep(3);

      ReactGA.event({
        category: "New Order",
        action: "packageSelected",
        label: "lblPackageSelected", // optional
        value: 91, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }
  }, [packageSelected]);

  useEffect(() => {
    setEmailWrong(!reEmailUnicode.test(emailField));

    ReactGA.event({
      category: "New Order",
      action: "EmailSet",
      label: "lblEmailSet", // optional
      value: 90, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }, [emailField]);

   useEffect(() => {

    const skus4categoryTmp = resetSKUs4Category();

    if (packageSelected && !skus4categoryTmp.includes(packageSelected.sku)) {
      setPackageSelected(null);
    }

    /* unselect selected products if they are not among skus4category1 */
    if ( products.find(p => p.selected && !skus4categoryTmp.includes(p.sku)) ) {
      let products2 = [...products];

      products2.forEach((p) => {
        if (!skus4categoryTmp.includes(p.sku)) {
          p.selected = false;
        }
      });
      setProducts(products2);
    }
  }, [
    sizeOfPropertySelected,
    typeOfPropertySelected,
    productsCategories,
  ]);

  function resetSKUs4Category() {
    const skus4categoryTmp = productsCategories
      .filter((productCategory) => {

        /* (StartA <= EndB) and (EndA >= StartB) https://stackoverflow.com/questions/3269434/whats-the-most-efficient-way-to-test-two-integer-ranges-for-overlap */
        return (
          typeOfPropertySelected &&
          sizeOfPropertySelected &&
          typeOfPropertySelected.id === productCategory.type &&
          sizeOfPropertySelected.min <= productCategory.max &&
          sizeOfPropertySelected.max >= productCategory.min
        );
      })
      .map((product) => product.sku);

    setSKUs4category(skus4categoryTmp);

    return skus4categoryTmp;
  }

  function scroll2top() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const handleNext = async () => {
    //console.log("handleNext activeStep=" + activeStep);
    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    //console.log("handleBack activeStep=" + activeStep);
    if (activeStep > 0) {
      // if (activeStep === 3) {
      //   const suitablePackages = products.filter(
      //     (product) =>
      //       skus4category.includes(product.sku) &&
      //       product.type.toUpperCase() === "PACKAGE"
      //   )
        
      //   if (suitablePackages.length === 0) {
      //     console.log("handleBack1 setActiveStep(1) activeStep=" + activeStep);
      //     setPrevStep(activeStep);
      //     setActiveStep(1); // skip Package Selection
      //   } else {
      //     console.log("handleBack2 setActiveStep(2) activeStep=" + activeStep);
      //     setPrevStep(activeStep);
      //     setActiveStep(2);
      //   }
      // } else if (activeStep === 4) {
      //     const suitablePackages = products.filter(
      //       (product) =>
      //         skus4category.includes(product.sku) &&
      //         product.type.toUpperCase() === "STANDALONE"
      //     )
          
      //     if (suitablePackages.length === 0) {
      //       console.log("handleBack3 setActiveStep(2) activeStep=" + activeStep);
      //       setPrevStep(activeStep);
      //       setActiveStep(2); // skip Package Selection
      //     } else {
      //       console.log("handleBack4 setActiveStep(3) activeStep=" + activeStep);
      //       setPrevStep(activeStep);
      //       setActiveStep(3);
      //     }
      // } else {
        setPrevStep(activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      // }
    }
  };

  const handleGo2Step = (e, step2go) => {
    //console.log("handleGo2Step step2go=" + step2go + " activeStep=" + activeStep);
    if (activeStep === step2go || !e.target.outerHTML.includes("MuiStep")) {
      //console.log("handleGo2Step do nothing");
      return;
    }
    //console.log("handleGo2Step goto=" + step2go);
    setPrevStep(activeStep);
    setActiveStep(step2go);
  };

  const handleReset = () => {
    scroll2top();
    //console.log("setActiveStep(0) curr activeStep=" + activeStep);
    setPrevStep(activeStep);
    setActiveStep(0);
  };

  const handleTypesOfPropertySelected = (selectedTypeOfProperty) => {

    if (typeOfPropertySelected && selectedTypeOfProperty.id === typeOfPropertySelected.id) {
      setTypeOfPropertySelected(null);
    } else {
      selectedTypeOfProperty.selected = true;
      setTypeOfPropertySelected(selectedTypeOfProperty);
    }
  };

  const handleSizesOfPropertySelected = (sizeOfProperty) => {

    if (sizeOfPropertySelected && sizeOfProperty.id === sizeOfPropertySelected.id) {
      setSizeOfPropertySelected(null);
      //console.log("setActiveStep(1) curr activeStep=" + activeStep);
      setPrevStep(activeStep);
      setActiveStep(1);
    } else {
      sizeOfProperty.selected = true;
      setSizeOfPropertySelected(sizeOfProperty);
      //console.log("setActiveStep(2) curr activeStep=" + activeStep);
      setPrevStep(activeStep);
      setActiveStep(2);
    }
  };

  const handleProductSelected = (product) => {
    let arrayTmp = [...products];
    if (product.type.toUpperCase() === "PACKAGE") {

      arrayTmp
        .filter((product) => product.type.toUpperCase() === "PACKAGE")
        .forEach((obj) => {
          if (obj.selected === undefined) {
            if (packageSelected != null && obj.sku === packageSelected.sku) {
              obj.selected = packageSelected.selected;
            } else {
              obj.selected = false;
            }
          }

          if (obj.sku !== product.sku) {
            obj.selected = false;
          }
        });
    }
    const objIndex = arrayTmp.findIndex((obj) => obj.sku === product.sku);

    arrayTmp[objIndex].selected = arrayTmp[objIndex].selected ? false : true;
    setProducts(arrayTmp);

    setPackageSelected(
      arrayTmp[objIndex].selected && product.type.toUpperCase() === "PACKAGE"
        ? product
        : null
    );

    // check if among selected products there is one with Floorplan
    const productsWithFloorplan = productsCategories.filter((category) => {
      return arrayTmp.some((product) => product.selected 
        && product.sku === category.sku 
        && category.type === typeOfPropertySelected.id 
        && category.wo === "floorplan");
    });  
    // show uploaded whe at least one Floorplan product selected
    const showUploader = productsWithFloorplan.length > 0;
    setShowUploader(showUploader);
  };

  const handleSubmitOrder = () => {
    const extRefId = (zipField + address1Field + apartmentField + cityField)
      .toLowerCase()
      .replace(/[\W_]+/g, "")
      .slice(0, 150);

    const selectedProducts = products
      .filter((product) => product.selected)
      .map((product) => {
        return { sku: product.sku, quantity: product.quantity };
      });

    const orderJson = {
      orders: {
        external_customer_id: emailField,
        external_project_id: extRefId,
        external_order_id: extOrderId,
        external_reference_id: extRefId,
        customer: {
          first_name: firstName,
          last_name: lastName,
          phone_mobile: phone1Field,
          phone_other: phone2Field,
          email: emailField,
          external_headoffice_id: "", //"IndyCustHO1",
          external_headoffice_name: "", //"Independent Customer HO #1",
          external_branchoffice_id: "", //"IndyCustBO1",
          external_branchoffice_name: "", //"Independent Customer BO #1",
        },
        sku_list: selectedProducts,
        preferred_date: preferredDateField,
        time_intervals: [
          preferredTimeField,
          //   "ANY_TIME",
          //   "FROM_9_TO_11",
          //   "FROM_11_TO_13",
          //   "FROM_13_TO_15",
          //   "FROM_15_TO_17",
          //   "FROM_0900_TO_0930",
          //   "FROM_0930_TO_1000",
          //   "FROM_1000_TO_1030",
          //   "FROM_1030_TO_1100",
          //   "FROM_1100_TO_1130",
          //   "FROM_1130_TO_1200",
          //   "FROM_1200_TO_1230",
          //   "FROM_1230_TO_1300",
          //   "FROM_1300_TO_1330",
          //   "FROM_1330_TO_1400",
          //   "FROM_1400_TO_1430",
          //   "FROM_1430_TO_1500",
          //   "FROM_1500_TO_1530",
          //   "FROM_1530_TO_1600"
        ],
        name_on_doorbell: nameondoorbellField,
        property: {
          street_address: address1Field,
          apartment: apartmentField,
          zip_code: zipField,
          city: cityField,
          property_type: typeOfPropertySelected.title,
          gross_area: sizeOfPropertySelected.max,
        },
        property_owner: {
          name: firstName + " " + lastName,
          phone_mobile: phone1Field,
          phone_other: phone2Field,
          email: emailField,
        },
      },
    };
    
    // put `upload_folder`, `uploaded_files` and `comment_zentuvo` into `orderJson` to pass them to the server
    if (uploadedFiles && uploadedFiles.size > 0) {
      orderJson.orders.upload_folder = uploadFloorplanFolderPrefix + extOrderId;

      const array = Array.from(uploadedFiles);

      orderJson.orders.uploaded_files = array.map((file) => file.name);

      const fileURLsArray = array.map((file) => file.uploadURL).join("', '");
      orderJson.orders.comment_zentuvo = `Floorplan(s) ['${fileURLsArray}'] uploaded to S3 folder: '${extRefId}'.`;
    }
    
    submitOrder(orderJson);
  };
  const submitOrder = (json) => {
    setError(null);
    setLoaded(false);
    try {
      setLoaded(true);
      const email = JSON.parse(window.localStorage.getItem("email"));
      const code = JSON.parse(window.localStorage.getItem("verification_code"));
      if (!email || !code) {
        alert("Please make sure you provided both email and password");
        try {
          step5Ref.current.scrollIntoView();
        } catch (error) {
          console.error("can't scroll at line #932");
        }
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      }
      fetch(
        process.env.REACT_APP_API_URL + "submitOrder2API",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa(email + ":" + code),
            "calling-site": "se360",
          },
          body: JSON.stringify(json),
        }
      )
        .then((response) => {
          setUploadedFiles(new Set());
          setExtOrderId(new Date().toISOString()); // to have different extOrderId
          ReactGA.event({
            category: "New Order",
            action: "OrderSubmit",
            label: "order placed successfully", // optional
            value: 89, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
          setLoaded(true);
        })
        .catch((error) => {
          setError(t("failedSubmitOrder") + error.message);
          ReactGA.event({
            category: "New Order",
            action: "OrderSubmit",
            label: "failedSubmitOrder" + error.message, // optional
            value: 88, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });
        });
    } catch (fetchError) {
      setError(t("failedSubmitOrder") + fetchError.message);
      ReactGA.event({
        category: "New Order",
        action: "OrderSubmit",
        label: "failedSubmitOrder" + fetchError.message, // optional
        value: 87, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
    }
  };

  const updateContacts = () => {
    try {
      const json = {
        first_name: firstName,
        last_name: lastName,
        phone1: phone1Field.replaceAll(" ", ""),
        phone2: phone2Field.replaceAll(" ", ""),
      };
      const email = JSON.parse(window.localStorage.getItem("email"));
      const code = JSON.parse(window.localStorage.getItem("verification_code"));
      if (!email || !code) {
        alert("Please make sure you provided both email and password");
        try {
          step5Ref.current.scrollIntoView();
        } catch (error) {
          console.error("can't scroll at line #999");
        }
        setPrevStep(activeStep);
        setActiveStep(5);
        return;
      }

      fetch(process.env.REACT_APP_API_URL + `updateTUsers`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(email + ":" + code),
        },
        body: JSON.stringify(json),
      })
        .then((response) => {
          if (response.status === 401) {
            alert("Either email or password is wrong");
            try {
              step5Ref.current.scrollIntoView();
            } catch (error) {
              console.error("can't scroll at line #1020");
            }
            setPrevStep(activeStep);
            setActiveStep(5);
          }
          setLoaded(true);
        })
        .catch((reason) => {
          console.error("updateContacts.onError", reason);
        });
    } catch (fetchError) {
      // this is silent for user because it is not critical
      console.error("Failed to update contacts. Error: " + fetchError.message);
    }
  };

  const handleSendVerificationCode = () => {
    if (!emailField) {
      alert(t("Please enter your E-mail address to receive password."));
      setPrevStep(activeStep);
      setActiveStep(5);
      return;
    } else if (isEmailWrong) {
      alert(t("EmailIsWrong"));
      setPrevStep(activeStep);
      setActiveStep(5);
      return;
    } else {
      sendVerificationCode();
    }
  };
  const sendVerificationCode = () => {
    setError(null);
    setLoaded(false);
    try {
      fetch(
        process.env.REACT_APP_API_URL +
          "sendEmailVerificationCode/" +
          emailField.trim()
      )
        .then((response) => {
          setLoaded(true);
          return response.json();
        })
        .then((data) => {
          if (!firstName) setFirstName(data.first_name);
          if (!lastName) setLastName(data.last_name);
          if (!phone1Field) setPhone1Field(data.phone1);
          if (!phone2Field) setPhone2Field(data.phone2);

          alert(t("CheckYourEmail"));
        });
    } catch (fetchError) {
      setError({
        message: "Sorry. Failed to send password. Error: " + fetchError.message,
      });
    }
  };

  const sumPrices = () => {
    try {
      return products
        .filter((product) => product.selected)
        .map((product) => product.price)
        .reduce((total, price) => total + price);
    } catch (error) {
      return 0;
    }
  };
  const sumPricesVAT = () => {
    try {
      return products
        .filter((product) => product.selected)
        .map((product) => Math.round(product.price + product.price * VAT))
        .reduce((total, price) => total + price);
    } catch (error) {
      return 0;
    }
  };

  /* Popover on product descripton */
  const [anchorPopoverl, setAnchorPopoverl] = React.useState(null);

  const handlePopoverClick = (event) => {
    setAnchorPopoverl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorPopoverl(null);
  };

  const steps = [
    t("Select type of property"),
    t("Select size of property"),
    t("Select package"),
    t("Select additional products"),
    t("Enter porperty info"),
    t("E-mail verification"),
    t("Fill contact form"),
    t("Verify Entered Information"),
    // "Confirm entered info and submit order",
  ];
  const getStepContent = (step) => {
    switch (step) {
      case 0 /* type Of Property */:
        return (
          <React.Fragment>
            <Container maxWidth="md" component="main">
              <Grid container spacing={5} alignItems="stretch">
                <div ref={step0Ref}></div>
                {typesOfProperty.map((typeOfProperty) => {
                  return (
                    // Enterprise card is full width at sm breakpoint
                    <Grid item key={typeOfProperty.id} xs={12} sm={6} md={4}>
                      <Card>
                        <CardHeader
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: typeOfProperty.title,
                              }}
                            />
                          }
                          subheader={typeOfProperty.subheader}
                          titleTypographyProps={{
                            align: "center",
                          }}
                          subheaderTypographyProps={{ align: "center" }}
                          className={classes.cardHeader}
                        />
                        <CardActions>
                          <Button
                            fullWidth
                            variant={
                              typeOfPropertySelected &&
                              typeOfProperty.id === typeOfPropertySelected.id
                                ? "contained"
                                : "outlined"
                            }
                            // color={typeOfPropertySelected && typeOfProperty.id === typeOfPropertySelected.id ? "primary" : "secondary"}
                            color="primary"
                            onClick={() =>
                              handleTypesOfPropertySelected(typeOfProperty)
                            }
                          >
                            {
                              /*typeOfProperty.selected*/
                              typeOfPropertySelected &&
                              typeOfProperty.id === typeOfPropertySelected.id
                                ? t("Unselect")
                                : t("Select")
                            }
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </React.Fragment>
        );
      case 1 /* size Of Property */:
        return (
          <React.Fragment>
            <Container maxWidth="md" component="main" ref={step1Ref}>
              <Grid container spacing={5} alignItems="stretch">
                {sizesOfProperty
                  .filter(
                    (sizeType) =>
                      null !== sizeType &&
                      null !== sizeType.type &&
                      null !== typeOfPropertySelected &&
                      null !== typeOfPropertySelected.id &&
                      sizeType.type === typeOfPropertySelected.id
                    //  && sizeOfPropertySelected.min
                  )
                  .map((size) => {
                    return (
                      // Enterprise card is full width at sm breakpoint
                      <Grid item key={size.id} xs={12} sm={6} md={4}>
                        <Card>
                          <CardHeader
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: size.title,
                                }}
                              />
                            }
                            subheader={size.subheader}
                            titleTypographyProps={{ align: "center" }}
                            subheaderTypographyProps={{ align: "center" }}
                            className={classes.cardHeader}
                          />
                          <CardContent className={classes.cardContent}>
                            <ul>
                              {typeof size.description == "string" ? (
                                <Typography
                                  component="li"
                                  variant="subtitle1"
                                  align="center"
                                  key={size.description}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: size.description,
                                    }}
                                  />
                                </Typography>
                              ) : (
                                size.description.map((line, idx) => (
                                  <Typography
                                    component="li"
                                    variant="subtitle1"
                                    align="center"
                                    key={idx}
                                  >
                                    {line}
                                  </Typography>
                                ))
                              )}
                            </ul>
                          </CardContent>
                          <CardActions>
                            <Button
                              fullWidth
                              variant={
                                /*size.selected*/
                                sizeOfPropertySelected &&
                                size.id === sizeOfPropertySelected.id
                                  ? "contained"
                                  : "outlined"
                              }
                              // color={sizeOfPropertySelected && size.id === sizeOfPropertySelected.id ? "primary" : "secondary"}
                              color="primary"
                              onClick={() =>
                                handleSizesOfPropertySelected(size)
                              }
                            >
                              {
                                /*size.selected*/
                                sizeOfPropertySelected &&
                                size.id === sizeOfPropertySelected.id
                                  ? t("Unselect")
                                  : t("Select")
                              }
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Container>
          </React.Fragment>
        );
      case 2 /* Packages */:
        return (
          <React.Fragment>
            <Container maxWidth="md" component="main" ref={step2Ref}>
              <Grid
                container
                spacing={5}
                alignItems="stretch"
              >
                {(skus4category && skus4category.length > 0
                  ? products.filter(
                      (product) =>
                        skus4category.includes(product.sku) &&
                        product.type.toUpperCase() === "PACKAGE"
                      //  && sizeOfPropertySelected.min
                    )
                  : []
                ).map((product) => {
                  return (
                    // Enterprise card is full width at sm breakpoint
                    <Grid
                      item
                      key={product.sku}
                      xs={12}
                      sm={6}
                      md={4}
                      // component={Card}
                      // className={classes.gridItemCard}
                      // alignItems="stretch"
                    >
                      <Card>
                        <CardHeader
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: product.title.replace("<br>", ""),
                              }}
                            />
                          }
                          // subheader={
                          // <div
                          //   dangerouslySetInnerHTML={{
                          //     __html: product.subheader,
                          //   }}
                          // />
                          // }
                          titleTypographyProps={{
                            align: "center",
                            variant: "h5", //'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit', "display4", 'display3', 'display2', 'display1', 'headline', 'title', 'subheading'
                          }}
                          subheaderTypographyProps={{ align: "center" }}
                          // action={
                          //   product.title === t("Pro") ? <StarIcon /> : null
                          // }
                          className={classes.cardHeader}
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            {product.sku === "FI-PKG-ETUOVI-15-T" ? (
                              <span>
                                <Typography
                                  component="h2"
                                  variant="h3"
                                  color="textPrimary"
                                >
                                  {Math.round(
                                    product.price + product.price * VAT
                                  )}
                                  €
                                </Typography>
                                <Typography variant="h5" color="textSecondary">
                                  <del>195€</del>
                                </Typography>
                              </span>
                            ) : (
                              <Typography
                                component="h2"
                                variant="h3"
                                color="textPrimary"
                              >
                                {Math.round(
                                  product.price + product.price * VAT
                                )}
                                €
                              </Typography>
                            )}
                          </div>
                          <ul>
                            {typeof product.description === "string" ? (
                              <React.Fragment>
                                <Button
                                  id={"DescrPBtn" + product.id}
                                  aria-describedby={
                                    "DescrPPopover" + product.id
                                  }
                                  // variant="contained"
                                  // color="primary"
                                  onClick={handlePopoverClick}
                                  fullWidth
                                  className={classes.buttonAsLink}
                                >
                                  {t("Show Description")}
                                </Button>
                                <Popover
                                  id={"DescrPPopover" + product.id}
                                  open={
                                    Boolean(anchorPopoverl && anchorPopoverl.id ===
                                    "DescrPBtn" + product.id)
                                  }
                                  anchorEl={anchorPopoverl}
                                  onClose={handlePopoverClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography
                                    // component="li"
                                    component="div"
                                    variant="subtitle1"
                                    align="center"
                                    key={product.description}
                                    className={classes.popoverTypography}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: product.description,
                                      }}
                                    />
                                  </Typography>
                                </Popover>
                              </React.Fragment>
                            ) : (
                              product.description.map((line, idx) => (
                                <Typography
                                  component="li"
                                  // component="div"
                                  variant="subtitle1"
                                  align="center"
                                  key={line + idx}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: line,
                                    }}
                                  />
                                </Typography>
                              ))
                            )}
                          </ul>
                        </CardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            variant={
                              // packageSelected.selected ? "contained" : "outlined"
                              packageSelected &&
                              product.sku === packageSelected.sku
                                ? "contained"
                                : "outlined"
                            }
                            // color={product.selected ? "primary" : "secondary"}
                            color="primary"
                            onClick={() => handleProductSelected(product)}
                          >
                            {packageSelected &&
                            product.sku === packageSelected.sku
                              ? t("Unselect")
                              : t("Select")}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                  //   else return "";
                })}
              </Grid>
            </Container>
          </React.Fragment>
        );
      case 3 /* Separate Products */:
        return (
          <React.Fragment>
            <Container maxWidth="md" component="main" ref={step3Ref}>
              <Grid container spacing={5} alignItems="stretch">
                {(skus4category && skus4category.length > 0
                  ? products.filter(
                      (product) =>
                        skus4category.includes(product.sku) &&
                        product.type.toUpperCase() === "STANDALONE"
                      //  && sizeOfPropertySelected.min
                    )
                  : []
                ).map((product) => {

                  const productsCategory = productsCategories.find((pc) => {
                    //TODO: to identify exact productsCategory also need check its type and size
                    return pc.sku === product.sku;
                  });
                  return (
                    // Enterprise card is full width at sm breakpoint
                    <Grid
                      item
                      key={product.sku}
                      xs={12}
                      sm={6}
                      md={4}
                      // component={Card}
                      // className={classes.gridItemCard}
                    >
                      <Card>
                        <CardHeader
                          title={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: product.title,
                              }}
                            />
                          }
                          // subheader={
                          //   <div
                          //     dangerouslySetInnerHTML={{
                          //       __html: product.subheader,
                          //     }}
                          //   />
                          // }
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          // action={product.title === "Pro" ? <StarIcon /> : null}
                          className={classes.cardHeader}
                        />
                        <CardContent>
                          <div className={classes.cardPricing}>
                            <span>
                              <Typography
                                component="h2"
                                variant="h3"
                                color="textPrimary"
                                display="inline"
                              >
                                {Math.round(
                                  product.price + product.price * VAT
                                )}
                                €
                              </Typography>
                              {productsCategory.perImg ? (
                                <Typography
                                  variant="h5"
                                  color="textSecondary"
                                  display="inline"
                                >
                                  {t("perImg")}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <ul>
                            {typeof product.description === "string" ? (
                              <React.Fragment>
                                <Button
                                  id={"DescrABtn" + product.id}
                                  aria-describedby={
                                    "DescrAPopover" + product.id
                                  }
                                  // variant="contained"
                                  // color="primary"
                                  onClick={handlePopoverClick}
                                  fullWidth
                                  className={classes.buttonAsLink}
                                >
                                  {t("Show Description")}
                                </Button>
                                <Popover
                                  id={"DescrAPopover" + product.id}
                                  open={
                                    Boolean(anchorPopoverl && anchorPopoverl.id ===
                                    "DescrABtn" + product.id)
                                  }
                                  anchorEl={anchorPopoverl}
                                  onClose={handlePopoverClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                  }}
                                >
                                  <Typography
                                    // component="li"
                                    component="div"
                                    variant="subtitle1"
                                    align="center"
                                    key={product.description}
                                    className={classes.popoverTypography}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: product.description,
                                      }}
                                    />
                                  </Typography>
                                </Popover>
                              </React.Fragment>
                            ) : (
                              product.description.map((line) => (
                                <Typography
                                  component="li"
                                  variant="subtitle1"
                                  align="center"
                                  key={line}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: line,
                                    }}
                                  />
                                </Typography>
                              ))
                            )}
                          </ul>
                        </CardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            variant={
                              product.selected ? "contained" : "outlined"
                            }
                            // color={product.selected ? "primary" : "secondary"}
                            color="primary"
                            onClick={() => handleProductSelected(product)}
                          >
                            {product.selected ? t("Unselect") : t("Select")}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                  // else return "";
                })}
              </Grid>
              <Grid
                item
                key={"floorplanUploadKey"}
                xs={12}
                sm={6}
                md={4}
              >
                <div id="floorplanUploadDiv" style={{ marginTop: '10px', marginBottom: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                  {showUploader && <S3UploadBeforeOrder path={uploadFloorplanFolderPrefix + extOrderId} onUploadComplete={handleUploadComplete} />}
                </div>
              </Grid>
            </Container>
          </React.Fragment>
        );
      case 4 /* Property Info */:
        return (
          <React.Fragment>
            <Typography variant="subtitle1" gutterBottom>
              {t("PropertyAddressExplanation")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PlacesAutocomplete
                  value={googleAddress}
                  onChange={(e) => setGoogleAddress(e)}
                  onSelect={handleGoogleAddressSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        required
                        id="addressGoogle"
                        name="addressGoogle"
                        label={t("Type address here")}
                        variant="standard"
                        fullWidth
                        autoComplete="address"
                        value={googleAddress}
                        onChange={(e) => setGoogleAddress(e)}
                        InputProps={{
                          ...getInputProps({
                            placeholder: "Search Property Address ...",
                            className: "location-search-input",
                            autoFocus: true,
                          }),
                        }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  id="city"
                  name="city"
                  label={t("City")}
                  variant="standard"
                  fullWidth
                  autoComplete="city"
                  value={cityField}
                  onChange={(e) => setCityField(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  required
                  id="zip"
                  name="zip"
                  label={t("Zip / Postal code")}
                  variant="standard"
                  fullWidth
                  autoComplete="zip"
                  value={zipField}
                  onChange={(e) => setZipField(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="address1"
                  name="address1"
                  label={t("Address line")}
                  variant="standard"
                  fullWidth
                  autoComplete="address"
                  value={address1Field}
                  onChange={(e) => setAddress1Field(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  id="apartment"
                  name="apartment"
                  label={t("Apartment")}
                  variant="standard"
                  fullWidth
                  autoComplete="shipping address-line2"
                  value={apartmentField}
                  onChange={(e) => setApartmentField(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="name_on_doorbell"
                  name="name_on_doorbell"
                  label={t("Hints to find address")}
                  variant="standard"
                  fullWidth
                  autoComplete="family-name"
                  value={nameondoorbellField}
                  onChange={(e) => setNameondoorbellField(e.target.value)}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      case 5 /* email */:
        return (
          <React.Fragment>
            <Grid item xs={12} sm={12} ref={step5Ref}>
              <Card className={classes.card6Root}>
                {/* <CardHeader>{t("WeNeedYourEmail")}</CardHeader> */}
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("WeNeedYourEmail")}
                  </Typography>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={t("E-mail")}
                    variant="standard"
                    // fullWidth
                    style={{ width: 300 }}
                    error={isEmailWrong}
                    autoComplete="email"
                    value={emailField}
                    onChange={(e) => setEmailField(e.target.value)}
                  />
                </CardContent>
              </Card>
              <Card className={classes.card6Root}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("EnterEmailAndPressNEXT")}
                  </Typography>
                  <Button
                    onClick={handleSendVerificationCode}
                    size="large"
                    // color="secondary"
                    variant="contained"
                    disabled={isEmailWrong}
                  >
                    {t("Send password to my E-mail")}
                  </Button>
                </CardContent>
              </Card>
              <Card className={classes.card6Root}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("EnterPassword")}
                  </Typography>
                  <TextField
                    required
                    id="emailVerificationCode"
                    name="emailVerificationCode"
                    label={t("Password")}
                    variant="standard"
                    autoComplete="verification"
                    disabled={isEmailWrong}
                    value={verificationCodeField}
                    onChange={(e) => setVerificationCodeField(e.target.value)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </React.Fragment>
        );
      case 6 /* password + Contact Info */:
        return (
          <React.Fragment>
            <Typography variant="h6" gutterBottom>
              {t("Contact info")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label={t("First name")}
                  variant="standard"
                  fullWidth
                  autoComplete="given-name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  label={t("Last name")}
                  variant="standard"
                  fullWidth
                  autoComplete="family-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                  required
                  id="phone1"
                  name="phone_mobile"
                  label={t("Phone (main)")}
                  fullWidth
                  autoComplete="phone"
                  value={phone1Field}
                  defaultCountry={"fi"}
                  preferredCountries={["fi", "se", "no", "dn", "de", "fr"]}
                  regions={["europe"]}
                  onChange={(e) => setPhone1Field(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                  id="phone2"
                  name="phone_other"
                  label={t("Phone (other)")}
                  fullWidth
                  autoComplete="phone"
                  value={phone2Field}
                  defaultCountry={"fi"}
                  preferredCountries={["fi", "se", "no", "dn", "de", "fr"]}
                  regions={["europe"]}
                  onChange={(e) => setPhone2Field(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="date"
                  label={t("Preferred Date")}
                  variant="standard"
                  style={{ width: 205 }}
                  type="date"
                  defaultValue={new Date(
                    new Date().getTime() + 24 * 60 * 60 * 1000
                  )
                    .toISOString()
                    .substr(0, 10)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={preferredDateField}
                  onChange={(e) =>
                    handleChangePreferredDateField(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.selectPreferredTime}>
                  <InputLabel id="select-preferred-time-label">
                    {t("Preferred Time")}
                  </InputLabel>
                  <Select
                    labelId="select-preferred-time-label"
                    id="select-preferred-time"
                    variant="standard"
                    value={preferredTimeField}
                    onChange={(e) => setPreferredTimeField(e.target.value)}
                  >
                    <MenuItem value={"ANY_TIME"}>{t("ANY_TIME")}</MenuItem>
                    <MenuItem value={"FROM_0900_TO_0930"}>
                      09:00 - 09:30
                    </MenuItem>
                    <MenuItem value={"FROM_0930_TO_1000"}>
                      09:30 - 10:00
                    </MenuItem>
                    <MenuItem value={"FROM_1000_TO_1030"}>
                      10:00 - 10:30
                    </MenuItem>
                    <MenuItem value={"FROM_1030_TO_1100"}>
                      10:30 - 11:00
                    </MenuItem>
                    <MenuItem value={"FROM_1100_TO_1130"}>
                      11:00 - 11:30
                    </MenuItem>
                    <MenuItem value={"FROM_1130_TO_1200"}>
                      11:30 - 12:00
                    </MenuItem>
                    <MenuItem value={"FROM_1200_TO_1230"}>
                      12:00 - 12:30
                    </MenuItem>
                    <MenuItem value={"FROM_1230_TO_1300"}>
                      12:30 - 13:00
                    </MenuItem>
                    <MenuItem value={"FROM_1300_TO_1330"}>
                      13:00 - 13:30
                    </MenuItem>
                    <MenuItem value={"FROM_1330_TO_1400"}>
                      13:30 - 14:00
                    </MenuItem>
                    <MenuItem value={"FROM_1400_TO_1430"}>
                      14:00 - 14:30
                    </MenuItem>
                    <MenuItem value={"FROM_1430_TO_1500"}>
                      14:30 - 15:00
                    </MenuItem>
                    <MenuItem value={"FROM_1500_TO_1530"}>
                      15:00 - 15:30
                    </MenuItem>
                    <MenuItem value={"FROM_1530_TO_1600"}>
                      15:30 - 16:00
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      case 7 /* confirm order */:
        return (
          <React.Fragment>
            <div
              style={{
                width: window.screen.width > 1000 ? "40%" : "90%",
                margin: "auto",
                paddingTop: "50px",
              }}
            >
              <MUIDataTable
                ref={step7Ref}
                title={t("Selected Products")}
                data={products.filter((product) => product.selected)}
                columns={[
                  {
                    name: "title",
                    label: t("Name"),
                    options: {
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: "sku",
                    label: t("SKU"),
                    options: {
                      display: false,
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: "price",
                    label: t("Price"),
                    options: {
                      display: false,
                      filter: false,
                      sort: false,
                    },
                  },
                  {
                    name: "priceVat",
                    label: t("Price+VAT"),
                    options: {
                      filter: false,
                      sort: false,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return Math.round(
                          tableMeta.rowData[2] + tableMeta.rowData[2] * VAT
                        );
                      },
                    },
                  },
                ]}
                options={{
                  filter: false,
                  search: false,
                  download: false,
                  print: false,
                  // viewColumns: false,
                  pagination: false,
                  selectableRows: "none",
                  // filterType: "dropdown",
                  // rowsPerPage: 3,
                  customFooter: (
                    count,
                    page,
                    rowsPerPage,
                    changeRowsPerPage,
                    changePage
                  ) => {
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            // variant="head"
                            size="small"
                            align="right"
                          >
                            <Typography variant="h7" className={classes.title}>
                              {t("Total Price")} : {sumPrices()}€ + {t("VAT")}
                              {Math.round(sumPrices() * VAT * 100) / 100}€ =
                            </Typography>
                            <Typography variant="h5" className={classes.title}>
                              {/* €{Math.round(sumPrices() + sumPrices() * VAT)} */}
                              {sumPricesVAT()}€
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  },
                }}
              />
            </div>
            <br></br>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <TextField
                  id="firstName"
                  name="firstName"
                  label={t("First name")}
                  variant="standard"
                  readOnly
                  value={firstName}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label={t("Last name")}
                  variant="standard"
                  readOnly
                  value={lastName}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  id="phone1"
                  name="phone_mobile"
                  label={t("Phone (main)")}
                  variant="standard"
                  readOnly
                  value={phone1Field}
                />
              </Grid>
              {phone2Field ? (
                <Grid item xs={4} sm={4}>
                  <TextField
                    id="phone2"
                    name="phone_other"
                    label={t("Phone (other)")}
                    variant="standard"
                    readOnly
                    value={phone2Field}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={4} sm={4}>
                <TextField
                  id="city"
                  name="city"
                  label={t("City")}
                  variant="standard"
                  readOnly
                  value={cityField}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  id="zip"
                  name="zip"
                  label={t("Zip / Postal code")}
                  variant="standard"
                  readOnly
                  value={zipField}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  id="address1"
                  name="address1"
                  label={t("Address line")}
                  variant="standard"
                  readOnly
                  value={address1Field}
                />
              </Grid>
              {apartmentField ? (
                <Grid item xs={4} sm={4}>
                  <TextField
                    id="apartment"
                    name="apartment"
                    label={t("Apartment")}
                    variant="standard"
                    readOnly
                    value={apartmentField}
                  />
                </Grid>
              ) : (
                ""
              )}
              {nameondoorbellField ? (
                <Grid item xs={4} sm={4}>
                  <TextField
                    id="name_on_doorbell"
                    name="name_on_doorbell"
                    label={t("Hints to find address")}
                    variant="standard"
                    readOnly
                    value={nameondoorbellField}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={4} sm={4}>
              <Box m={2} pt={3} rt={10}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        {...{ inputProps: { "aria-label": "Checkbox demo" } }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        checked={isTermsAndConditionAccepted}
                        onChange={handleChangeAcceptTermsAndConditionCheckbox}
                      />
                    }
                    label={
                      <span>
                        {t("Before submit order please")}{" "}
                        <a
                          href={t("link-terms-of-use")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("Accept terms & conditions")}
                        </a>
                      </span>
                    }
                  />
                </FormGroup>
              </Box>
            </Grid>
          </React.Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  if (error) return <Error error={error} />;
  if (!isLoaded || !products) return <Loading />;

  return (
    <div className={classes.root1}>
      <CssBaseline />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label} onClick={(e) => handleGo2Step(e, index)}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {t("Back")}
                  </Button>
                  <Button
                    disabled={
                      activeStep === steps.length - 1 &&
                      !isTermsAndConditionAccepted
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? t("Submit Order")
                      : t("Next")}
                    {/* {activeStep === steps.length - 1
                      ? isTermsAndConditionAccepted
                        ? t("Submit Order")
                        : t("Accept Terms&Conditions then Submit Order")
                      : t("Next")} */}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            {t(
              "SuccessfullySubmitedOrder"
            )}
          </Typography>
          <Button onClick={handleReset} className={classes.button}>
            {t("Place another order")}
          </Button>
        </Paper>
      )}
    </div>
  );
}
